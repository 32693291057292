import { ConnectSlackCurriculumItemBannerFragment$key } from "@/content-usage/drawer/system-tasks/__generated__/ConnectSlackCurriculumItemBannerFragment.graphql"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import SlackIcon from "@/core/ui/images/logos/slack-icon-with-background_48x48.svg"
import { DiscoButton, DiscoText } from "@disco-ui"
import DiscoBanner from "@disco-ui/banner/DiscoBanner"
import { useTheme } from "@material-ui/core"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

type Props = {
  contentUsageKey: ConnectSlackCurriculumItemBannerFragment$key | null
}

function ConnectSlackCurriculumItemBanner(props: Props) {
  const { contentUsageKey } = props
  const contentUsageDrawer = useContentUsageDrawer()
  const theme = useTheme()

  const contentUsage = useFragment<ConnectSlackCurriculumItemBannerFragment$key>(
    graphql`
      fragment ConnectSlackCurriculumItemBannerFragment on ContentUsage {
        viewerHasCompleted
      }
    `,
    contentUsageKey
  )

  const shouldPreview = !contentUsageKey

  const Banner = (
    <DiscoBanner
      message={
        <>
          <DiscoText variant={"body-md-600"}>{"Connect Slack"}</DiscoText>
          <DiscoText>{"Join the Slack community for discussions."}</DiscoText>
        </>
      }
      buttons={
        <DiscoButton
          onClick={shouldPreview ? undefined : openConnectSlackDrawer}
          customButtonColor={{
            color: theme.palette.common.white,
            backgroundColor: "#4A154B",
            hover: {
              color: theme.palette.common.white,
              backgroundColor: "#4A154B",
            },
          }}
        >
          {shouldPreview ? "Not Available in Preview" : "Connect Slack"}
        </DiscoButton>
      }
      variant={"slack"}
      icon={<SlackIcon />}
    />
  )

  if (contentUsage?.viewerHasCompleted)
    return (
      <DiscoBanner
        message={
          <DiscoText variant={"body-md-600"}>
            {"Congratulations! Your Disco account is now connected to Slack"}
          </DiscoText>
        }
        buttons={
          <DiscoButton leftIcon={"check-circle"} color={"success"}>
            {"Connected"}
          </DiscoButton>
        }
        variant={"slack"}
        icon={<SlackIcon />}
      />
    )

  return Banner

  function openConnectSlackDrawer() {
    contentUsageDrawer.setParams({
      drawerConnectSlackTab: "connect-slack",
    })
  }
}

export default ConnectSlackCurriculumItemBanner

/**
 * @generated SignedSource<<32d22cf39a93173a4f76661fe8b785cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentType = "assignment" | "assignment_submission" | "post" | "occurrence" | "comment" | "module" | "text" | "video" | "attachment" | "embed" | "quiz" | "custom" | "survey" | "confirmation" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContentUsageGridItemFragment$data = {
  readonly id: string;
  readonly showComments: boolean | null;
  readonly content: {
    readonly id: string;
    readonly type: ContentType;
    readonly label: string;
    readonly name: string | null;
    readonly description: string | null;
    readonly coverPhoto: string | null;
    readonly coverVideo: string | null;
    readonly thumbnailUrl: string | null;
    readonly showCoverPlayIcon: boolean;
    readonly coverVideoAssetUsage: {
      readonly id: string;
      readonly asset: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"DiscoVideoFragment">;
      };
    } | null;
    readonly productId: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"ContentFooterSection_ContentFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"DeleteContentUsageButtonFragment" | "ContentFooterSection_ContentUsageFragment" | "ContentUsageUtils_useContentUsagePathContentUsageFragment">;
  readonly " $fragmentType": "ContentUsageGridItemFragment";
};
export type ContentUsageGridItemFragment$key = {
  readonly " $data"?: ContentUsageGridItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentUsageGridItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentUsageGridItemFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showComments",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "coverPhoto",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "coverVideo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnailUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showCoverPlayIcon",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetUsage",
          "kind": "LinkedField",
          "name": "coverVideoAssetUsage",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Asset",
              "kind": "LinkedField",
              "name": "asset",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "DiscoVideoFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "productId",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ContentFooterSection_ContentFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteContentUsageButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentFooterSection_ContentUsageFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentUsageUtils_useContentUsagePathContentUsageFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};
})();

(node as any).hash = "5fef503c247e5b5620923bff819dde35";

export default node;

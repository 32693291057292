import {
  postWebViewMessage,
  WebViewMessageType,
} from "@/apps/util/hooks/useWebViewMessage"
import QuizSubmissionEditorFooter from "@/content-usage/drawer/quizzes/submission-editor/QuizSubmissionEditorFooter"
import QuizSubmissionEditorHeader from "@/content-usage/drawer/quizzes/submission-editor/QuizSubmissionEditorHeader"
import QuizSubmissionForm from "@/content-usage/drawer/quizzes/submission-editor/QuizSubmissionForm"
import { QuizSubmissionEditorContentUsageFragment$key } from "@/content-usage/drawer/quizzes/submission-editor/__generated__/QuizSubmissionEditorContentUsageFragment.graphql"
import { QuizSubmissionEditorFormMutation } from "@/content-usage/drawer/quizzes/submission-editor/__generated__/QuizSubmissionEditorFormMutation.graphql"
import QuizSubmissionResult from "@/content-usage/drawer/quizzes/submission-result/QuizSubmissionResult"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import UnsavedChangesModalProvider, {
  useInitUnsavedChangesModalContext,
} from "@/core/context/UnsavedChangesModalProvider"
import { useFormStore } from "@/core/form/store/FormStore"
import useIsWebView from "@/product/util/hook/useIsWebView"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import {
  useStartWebFormSubmission,
  WebFormAnswerInput,
} from "@/web-form/utils/webFormFillerUtils"
import {
  getQuizSubmissionInput,
  useWebFormSubmissionsQuery,
} from "@/web-form/utils/webFormQueryUtils"
import { WebFormSubmissionsUtils } from "@/web-form/utils/webFormSubmissionsUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ScrollShadowContainer from "@components/scroll-shadow/ScrollShadowContainer"
import { ArrayUtils } from "@utils/array/arrayUtils"
import useEffectOnUpdate from "@utils/hook/useEffectOnUpdate"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useEffect, useRef, useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface QuizSubmissionEditorProps extends TestIDProps {
  revisionId: GlobalID
  contentLabel: string
  contentUsageKey: QuizSubmissionEditorContentUsageFragment$key
  title?: string | null
  hideHeader?: boolean
}

export type CreateWebFormAnswersInput = {
  webFormRevisionId: GlobalID
  answers: WebFormAnswerInput[]
  contentUsageId: GlobalID
}

export type QuizSubmissionEditorFormState = {
  webFormSubmission: CreateWebFormAnswersInput
  activeAnswerIndex: number
  submissionConnectionId?: string | null
}

function QuizSubmissionEditor({
  testid = "QuizSubmissionEditor",
  revisionId,
  contentLabel,
  contentUsageKey,
  hideHeader = false,
}: QuizSubmissionEditorProps) {
  const isWebView = useIsWebView()
  const usageDrawer = useContentUsageDrawer()
  const [buttonsContainerRef, setButtonsContainerRef] = useState<HTMLDivElement | null>(
    null
  )
  const isGoingBack = useRef(false)
  const isRetrying = Boolean(Number(usageDrawer.params.drawerQuizRetry))

  const contentUsage = useFragment<QuizSubmissionEditorContentUsageFragment$key>(
    graphql`
      fragment QuizSubmissionEditorContentUsageFragment on ContentUsage {
        id
        content {
          name
        }
        ...QuizSubmissionFormContentUsageFragment
      }
    `,
    contentUsageKey
  )

  const { submissions, revision, refetch } = useWebFormSubmissionsQuery({
    revisionId,
    usageId: contentUsage.id,
    isRetrying,
    submissionIds: usageDrawer.params.submissionId
      ? [usageDrawer.params.submissionId]
      : [],
    orderBy: { field: "created_at", direction: "DESC" },
    first: 1,
  })

  const { submission, result } = submissions.length
    ? submissions[0]
    : { submission: null, result: null }

  // If a submission hasn't been started yet, create one so the submission duration will
  // be tracked starting from the moment the first question is visible
  const startSubmission = useStartWebFormSubmission({
    revisionId,
    usageId: contentUsage.id,
  })
  useEffect(() => {
    if (submission?.id) return
    startSubmission().then((newSubmissionId) => {
      // Refetch the new submission so if the drawer is closed and reopened, it will show
      // the in-progress submission. For some reason useWebFormSubmissionsQuery won't
      // always run the query if fetch policy is "network-only" (probably due to internal
      // quirks of Relay.useRefetchableQuery), so a manual refetch fixes that.
      if (newSubmissionId) refetch()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submission?.id])

  // For webview provide data for mobile app to redirect to results screen
  useEffectOnUpdate(() => {
    if (isWebView && submission?.result) {
      postWebViewMessage({
        type: WebViewMessageType.navigate_to_quiz_results,
        params: {
          submissionId: submission.id,
          revisionId,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submission?.result])

  const questions = Relay.connectionToArray(revision?.questions)
  const submissionInput = getQuizSubmissionInput(revision, submission)
  const firstUnansweredIndex = submissionInput.answers.findIndex(
    (a) => a.isSkipped !== false
  )

  const form = useFormStore<
    QuizSubmissionEditorFormMutation,
    QuizSubmissionEditorFormState
  >(
    graphql`
      mutation QuizSubmissionEditorFormMutation($input: CreateWebFormAnswerInput!) {
        response: createWebFormAnswer(input: $input) {
          node {
            ...webFormQueryUtils_submissionFragment @relay(mask: false)
            contentUsage {
              ...ContentUsageUtils_ContentCompletionFragment
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      /**
       * prefer to go to the first unanswered question,
       * go to the last question if there's a submission (all questions answered),
       * or the first if not
       */
      activeAnswerIndex:
        firstUnansweredIndex > -1
          ? firstUnansweredIndex
          : submission
          ? questions.length - 1
          : 0,
      webFormSubmission: {
        contentUsageId: contentUsage.id,
        ...submissionInput,
      },
    },
    { requireChangeToSubmit: false }
  )
  const isComplete = !!submission?.completedAt && !isRetrying
  const modal = useInitUnsavedChangesModalContext()

  const { selectedOptions: initialOptions } =
    form.initialState.webFormSubmission?.answers[form.state.activeAnswerIndex] ?? {}
  const { selectedOptions } =
    form.state.webFormSubmission?.answers[form.state.activeAnswerIndex] ?? {}

  useEffect(() => {
    const { added, removed } = ArrayUtils.diff(
      initialOptions ?? [],
      selectedOptions ?? []
    )
    modal.setUnsavedChanges(Boolean(added.length || removed.length))
  }, [form.state.activeAnswerIndex, initialOptions, selectedOptions, modal])

  const isCompleting = form.state.activeAnswerIndex === questions.length - 1

  const classes = useStyles()

  return (
    <>
      {!hideHeader && (
        <QuizSubmissionEditorHeader
          testid={testid}
          title={contentUsage.content.name ?? "Untitled"}
          overlineTitle={contentLabel}
          onClose={handleClose}
          onBack={handleBack}
          totalQuestions={isComplete ? undefined : questions.length}
          activeAnswerIndex={isComplete ? undefined : form.state.activeAnswerIndex}
          shouldDisplayBackSpinner={form.isSubmitting && isGoingBack.current}
          showResultSummaryButton={Boolean(submission?.hasAnswerKey)}
        />
      )}
      {isComplete ? (
        <QuizSubmissionResult
          form={form}
          result={result}
          submission={submission}
          revision={revision}
          contentLabel={contentLabel}
          contentUsageId={contentUsage.id}
        />
      ) : (
        <UnsavedChangesModalProvider {...modal}>
          <ScrollShadowContainer
            classes={{ parentContainer: classes.quizQuestionsBackground }}
          >
            <QuizSubmissionForm
              form={form}
              revision={revision}
              contentLabel={contentLabel}
              buttonsContainerRef={buttonsContainerRef}
              isCompleting={isCompleting}
              handleSubmit={handleSubmit}
              contentUsageKey={contentUsage}
            />
          </ScrollShadowContainer>
        </UnsavedChangesModalProvider>
      )}
      {!isComplete && (
        <QuizSubmissionEditorFooter ref={(ref) => setButtonsContainerRef(ref)} />
      )}
    </>
  )

  async function handleSubmit(allowCompletion = true) {
    const answer = form.state.webFormSubmission.answers[form.state.activeAnswerIndex]
    const contentUsageId = form.state.webFormSubmission.contentUsageId!

    // If going back and no answer is entered, skip saving so required questions don't fail validation
    if (!allowCompletion && !answer.body && !answer.selectedOptions?.length)
      return { success: true }

    const { didSave, response } = await form.submit({
      contentUsageId,
      webFormQuestionId: answer.webFormQuestionId,
      answer: {
        ...answer,
        isSkipped: false, // we can pass false here since we want the mutation to validate if the answer is skipped
      },
      isCompleting: isCompleting && allowCompletion,
    })

    if (!didSave) return { success: false }

    // update the submission input with the latest answers
    form.state.webFormSubmission = {
      contentUsageId,
      ...getQuizSubmissionInput(revision, response?.node),
    }

    // Invalidate the submissions count so if an admin submits the form
    // it will immediately show that submissions exist for this usage.
    if (response?.node) {
      WebFormSubmissionsUtils.invalidateWebForm({ webFormId: response.node.webFormId })
    }

    // Add the submissionId to the URL
    usageDrawer.setParams({
      submissionId: response?.node?.id,
      drawerQuizRetry: undefined,
    })

    return { success: didSave }
  }

  function handleClose() {
    modal.handleLeave({
      onLeave: usageDrawer.close,
    })
  }

  async function handleBack() {
    if (submission?.completedAt) return leaveQuestions()
    isGoingBack.current = true
    // save the answer before leaving, but don't allow completion
    const { success } = await handleSubmit(false)
    isGoingBack.current = false
    if (success) return leaveQuestions()
    // if for some reason the answer couldn't be saved, ask if the user wants to leave (without saving)
    modal.handleLeave({
      onLeave: leaveQuestions,
    })
  }

  function leaveQuestions() {
    usageDrawer.setParams({ drawerQuizTab: undefined, drawerQuizRetry: undefined })
  }
}

const useStyles = makeUseStyles((theme) => ({
  quizQuestionsBackground: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
}))

export default observer(QuizSubmissionEditor)

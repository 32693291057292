/**
 * @generated SignedSource<<91879763c499328d23430145062b13e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentModuleCompletedUsersAvatarStackQuery$variables = {
  id: string;
};
export type ContentModuleCompletedUsersAvatarStackQuery$data = {
  readonly contentUsage: {
    readonly content?: {
      readonly name: string | null;
    };
    readonly completedProductMemberships?: {
      readonly totalCount: number;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly member: {
            readonly id: string;
            readonly first_name: string | null;
            readonly last_name: string | null;
            readonly avatar: string;
          };
        };
      }>;
    };
    readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
  } | null;
};
export type ContentModuleCompletedUsersAvatarStackQuery = {
  variables: ContentModuleCompletedUsersAvatarStackQuery$variables;
  response: ContentModuleCompletedUsersAvatarStackQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductMembershipNodeConnection",
  "kind": "LinkedField",
  "name": "completedProductMemberships",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductMembershipNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductMembership",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "member",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                {
                  "alias": "first_name",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstName",
                  "storageKey": null
                },
                {
                  "alias": "last_name",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "avatar",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerPermissions",
  "storageKey": null
},
v6 = [
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "memberPermissions",
  "storageKey": null
},
v8 = [
  {
    "alias": "nonNullProductId",
    "args": null,
    "kind": "ScalarField",
    "name": "productId",
    "storageKey": null
  },
  (v5/*: any*/),
  (v7/*: any*/)
],
v9 = [
  {
    "alias": "nullableProductId",
    "args": null,
    "kind": "ScalarField",
    "name": "productId",
    "storageKey": null
  },
  (v5/*: any*/),
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContentModuleCompletedUsersAvatarStackQuery",
    "selections": [
      {
        "alias": "contentUsage",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "usePermissionsFragment"
              }
            ],
            "type": "ContentUsage",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContentModuleCompletedUsersAvatarStackQuery",
    "selections": [
      {
        "alias": "contentUsage",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": (v6/*: any*/),
                    "type": "Organization",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "nonNullProductId",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      (v7/*: any*/)
                    ],
                    "type": "Product",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v8/*: any*/),
                    "type": "Event",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v9/*: any*/),
                    "type": "Comment",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v9/*: any*/),
                    "type": "Feed",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v9/*: any*/),
                    "type": "Post",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "nullableProductIdString",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "productId",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      (v7/*: any*/)
                    ],
                    "type": "ContentUsage",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v9/*: any*/),
                    "type": "ChatChannel",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v9/*: any*/),
                    "type": "Dashboard",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v8/*: any*/),
                    "type": "AssignmentSubmission",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v6/*: any*/),
                    "type": "PathwayGroup",
                    "abstractKey": null
                  }
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "type": "ContentUsage",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fcdc867ec707fb9122900cabd5a0a0d3",
    "id": null,
    "metadata": {},
    "name": "ContentModuleCompletedUsersAvatarStackQuery",
    "operationKind": "query",
    "text": "query ContentModuleCompletedUsersAvatarStackQuery(\n  $id: ID!\n) {\n  contentUsage: node(id: $id) {\n    __typename\n    ... on ContentUsage {\n      content {\n        name\n        id\n      }\n      completedProductMemberships {\n        totalCount\n        edges {\n          node {\n            id\n            member {\n              id\n              first_name: firstName\n              last_name: lastName\n              avatar\n            }\n          }\n        }\n      }\n      ...usePermissionsFragment\n    }\n    id\n  }\n}\n\nfragment usePermissionsFragment on Node {\n  __isNode: __typename\n  __typename\n  ... on Organization {\n    viewerPermissions\n  }\n  ... on Product {\n    nonNullProductId: id\n    viewerPermissions\n    memberPermissions\n  }\n  ... on Event {\n    nonNullProductId: productId\n    viewerPermissions\n    memberPermissions\n  }\n  ... on Comment {\n    nullableProductId: productId\n    viewerPermissions\n    memberPermissions\n  }\n  ... on Feed {\n    nullableProductId: productId\n    viewerPermissions\n    memberPermissions\n  }\n  ... on Post {\n    nullableProductId: productId\n    viewerPermissions\n    memberPermissions\n  }\n  ... on ContentUsage {\n    nullableProductIdString: productId\n    viewerPermissions\n    memberPermissions\n  }\n  ... on ChatChannel {\n    nullableProductId: productId\n    viewerPermissions\n    memberPermissions\n  }\n  ... on Dashboard {\n    nullableProductId: productId\n    viewerPermissions\n    memberPermissions\n  }\n  ... on AssignmentSubmission {\n    nonNullProductId: productId\n    viewerPermissions\n    memberPermissions\n  }\n  ... on PathwayGroup {\n    viewerPermissions\n  }\n}\n"
  }
};
})();

(node as any).hash = "0f194e2b6fc783db5798c17d9d3a31f1";

export default node;

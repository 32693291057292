import useAddCompletedSlackInvite from "@/apps/list/app/slack/util/useAddCompletedSlackInvite"
import DeleteContentUsageButton from "@/content-usage/buttons/DeleteContentUsageButton"
import EditSlackInviteContentButton from "@/content-usage/buttons/EditSlackInviteContentButton"
import { ContentModuleUtils } from "@/content-usage/ContentModuleUtils"
import ContentUsagePrerequisiteList from "@/content-usage/ContentUsagePrerequisiteList"
import ContentUsageLockIcon from "@/content-usage/modules/list/ContentUsageLockIcon"
import { ContentUsageListItemFragment$key } from "@/content-usage/modules/list/__generated__/ContentUsageListItemFragment.graphql"
import ContentThumbnailWithDetails, {
  ContentThumbnailWithDetailsSkeleton,
} from "@/content/detail/ContentThumbnailWithDetails"
import ContentUtils from "@/content/util/contentUtils"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { GlobalDrawerParams } from "@/core/context/GlobalDrawerProvider"
import { useNotificationsContext } from "@/core/context/NotificationsContext"
import ReorderIcon from "@/core/ui/iconsax/linear/custom-dots-tune.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import CurriculumProfileListModal from "@/product/course/curriculum/common/modal/CurriculumProfileListModal"
import Relay from "@/relay/relayUtils"
import CheckmarkIcon from "@assets/disco/icons/color-icons/checkmark-circle.svg"
import styleIf from "@assets/style/util/styleIf"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import CountBadge from "@components/square-count-badge/CountBadge"
import { displayErrorToast } from "@components/toast/ToastProvider"
import {
  DiscoChip,
  DiscoChipSkeleton,
  DiscoLink,
  DiscoLinkProps,
  DiscoSection,
  DiscoText,
} from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import { useMediaQuery, useTheme } from "@material-ui/core"
import useDisclosure from "@utils/hook/useDisclosure"
import usePermissions from "@utils/hook/usePermissions"
import useScrollToHash from "@utils/hook/useScrollToHash"
import { TestIDProps } from "@utils/typeUtils"
import { setSearchParams } from "@utils/url/urlUtils"
import classNames from "classnames"
import { useRef } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface ContentUsageListItemProps extends TestIDProps {
  hideReorderIcon?: boolean
  contentUsageKey: ContentUsageListItemFragment$key
}

function ContentUsageListItem({
  testid,
  contentUsageKey,
  hideReorderIcon,
}: ContentUsageListItemProps) {
  const activeProduct = useActiveProduct()!
  const { getUnreadNotifications } = useNotificationsContext()

  const contentUsage = useFragment<ContentUsageListItemFragment$key>(
    graphql`
      fragment ContentUsageListItemFragment on ContentUsage
      @argumentDefinitions(roles: { type: "[ProductRole!]" }) {
        id
        entity
        entityId
        dueAt
        viewerHasCompleted
        isLocked
        hasPrerequisites
        confirmationModalContent {
          id
        }
        content {
          type
          label
          name
          description
          systemTaskKind
          ...ContentThumbnailWithDetails_ContentFragment
        }
        module {
          usages {
            edges {
              node {
                id
                releasedAt
              }
            }
          }
        }
        product {
          id
          slug
          slack {
            id
            inviteURL
          }
          productMemberships(roles: $roles) {
            totalCount
          }
        }
        completedProductMemberships {
          totalCount
        }

        ...ContentThumbnailWithDetails_ContentUsageFragment
        ...EditSlackInviteContentButton_ContentUsageFragment
        ...ContentUsageLockIconFragment
        ...DeleteContentUsageButtonFragment
        ...usePermissionsFragment
      }
    `,
    contentUsageKey
  )

  const { content } = contentUsage

  const isSlackInvite = content.systemTaskKind === "slack_invite"

  const unreadSubmissionNotifications = getUnreadNotifications({
    contentUsageId: contentUsage.id,
    kinds: ["assignment-submitted", "quiz-submitted"],
  })

  const contentLabel = ContentUtils.useContentLabel({
    content: contentUsage.content,
    entity: contentUsage.entity,
  })

  const showOnHoverClasses = useShowOnHoverStyles({
    disabled: hideReorderIcon,
    hideOnTouchscreen: true,
  })
  const theme = useTheme()

  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  const { isOpen, onOpen, onClose } = useDisclosure(false)
  const {
    isOpen: prerequisiteModalIsOpen,
    onOpen: openPrerequisiteModal,
    onClose: closePrerequisiteModal,
  } = useDisclosure(false)

  const permissions = usePermissions(contentUsage)
  const showManageActions = permissions.has("content.manage")
  const addCompletedSlackInvite = useAddCompletedSlackInvite()
  const classes = useStyles({ isLocked: contentUsage.isLocked && !showManageActions })

  const showConfirmationEnabled =
    Boolean(contentUsage.confirmationModalContent) && showManageActions

  const moduleContentUsage = Relay.connectionToArray(contentUsage.module?.usages)[0]
  const completedMembers = Relay.connectionToArray(
    contentUsage.completedProductMemberships
  )

  const showCompletedUsers = Boolean(
    // No space on xs screens for this
    !isXsDown &&
      // If the module is released
      ContentModuleUtils.isReleased(moduleContentUsage) &&
      // Must be admin to see completed count
      showManageActions &&
      // Manual setting for evergreen courses
      activeProduct.curriculum?.showCompletedUsers &&
      // Only show if there are completed users
      completedMembers?.length &&
      // Only show if the product has members
      contentUsage.product?.productMemberships?.totalCount
  )

  const showViewerCompleted = !showManageActions && contentUsage.viewerHasCompleted

  const itemRef = useRef<HTMLDivElement | null>(null)
  useScrollToHash(itemRef, contentUsage.id)

  return (
    <>
      <DiscoLink {...getLinkProps()} textDecoration={"none"}>
        <DiscoSection
          ref={(r) => (itemRef.current = r)}
          className={classNames(classes.itemContainer, showOnHoverClasses.hoverable)}
          testid={testid}
          id={contentUsage.id}
        >
          <div className={classes.itemHeader}>
            <div className={classes.lhsContainer}>
              {!isSmDown && showManageActions && !hideReorderIcon && (
                <div
                  className={classNames(
                    classes.dragButtonContainer,
                    showOnHoverClasses.showable
                  )}
                >
                  <ReorderIcon width={16} height={16} />
                </div>
              )}

              <ContentThumbnailWithDetails
                testid={"ContentUsageListItem"}
                contentKey={content}
                contentUsageKey={contentUsage}
                thumbnailWidth={"120px"}
                thumbnailHeight={"60px"}
                textAdornment={
                  <>
                    {((showManageActions && contentUsage.hasPrerequisites) ||
                      contentUsage?.isLocked) && (
                      <>
                        <div className={classes.circle} />
                        <ContentUsageLockIcon
                          usageKey={contentUsage}
                          height={16}
                          width={16}
                        />
                        <DiscoText variant={"body-xs-500"} color={"groovy.neutral.500"}>
                          {showManageActions ? "Has Prerequisites" : "Locked"}
                        </DiscoText>
                      </>
                    )}
                    {showConfirmationEnabled && (
                      <>
                        <div className={classes.circle} />
                        <DiscoText variant={"body-xs-500"} color={"groovy.neutral.500"}>
                          {"Confirmation Enabled"}
                        </DiscoText>
                      </>
                    )}
                  </>
                }
              />
            </div>
            <div className={classes.rhsContainer}>
              {showManageActions && unreadSubmissionNotifications.length > 0 && (
                <CountBadge count={unreadSubmissionNotifications.length} />
              )}
              {showManageActions && (
                <CurriculumProfileListModal
                  isOpen={isOpen}
                  onClose={onClose}
                  contentUsageId={contentUsage.id}
                />
              )}
              {showCompletedUsers && (
                <DiscoChip
                  onClick={(e) => {
                    // Stop propagation to the list item which would open the content usage drawer
                    e.preventDefault()
                    e.stopPropagation()
                    onOpen()
                  }}
                  color={"grey"}
                  label={`${contentUsage.completedProductMemberships.totalCount}/${contentUsage.product?.productMemberships?.totalCount} completed`}
                  testid={"ContentUsageListItem.completed-count"}
                />
              )}
              {!showManageActions && (contentUsage.isLocked || showViewerCompleted) && (
                <div className={classes.completionContainer}>
                  {!showManageActions && contentUsage.isLocked && (
                    <div className={classes.lockIcon}>
                      <ContentUsageLockIcon
                        usageKey={contentUsage}
                        width={24}
                        height={24}
                      />
                    </div>
                  )}

                  {!showManageActions &&
                    !contentUsage.isLocked &&
                    showViewerCompleted && (
                      <CheckmarkIcon
                        data-testid={"ContentUsageListItem.completion-icon.complete"}
                        className={classes.progressIcon}
                      />
                    )}
                </div>
              )}

              {showManageActions && (
                <div className={classes.buttonsContainer}>
                  <DiscoMoreActionsDropdown testid={`${testid}.dropdown`}>
                    {isSlackInvite && (
                      <EditSlackInviteContentButton contentUsageKey={contentUsage}>
                        {(buttonProps) => (
                          <DiscoDropdownItem
                            {...buttonProps}
                            title={`Edit ${contentLabel}`}
                            testid={`${testid}.dropdown.edit`}
                          />
                        )}
                      </EditSlackInviteContentButton>
                    )}
                    <DeleteContentUsageButton contentUsageKey={contentUsage}>
                      {(buttonProps) => (
                        <DiscoDropdownItem
                          {...buttonProps}
                          title={`Delete ${contentLabel}`}
                          testid={`${testid}.dropdown.delete`}
                        />
                      )}
                    </DeleteContentUsageButton>
                  </DiscoMoreActionsDropdown>
                </div>
              )}
            </div>
          </div>
        </DiscoSection>
      </DiscoLink>
      <ContentUsagePrerequisiteList
        contentUsageId={contentUsage.id}
        isOpen={prerequisiteModalIsOpen}
        closePrerequisitesModal={closePrerequisiteModal}
        openPrerequisites={openPrerequisiteModal}
      />
    </>
  )

  function getLinkProps(): DiscoLinkProps {
    if (!showManageActions && contentUsage.isLocked) {
      return { onClick: openPrerequisiteModal, to: {} }
    }
    if (contentUsage.content.systemTaskKind === "slack_invite") {
      return {
        onClick: async () => {
          window.open(contentUsage.product?.slack?.inviteURL || "", "_blank")

          if (!contentUsage.viewerHasCompleted) {
            try {
              await addCompletedSlackInvite()
            } catch (error) {
              displayErrorToast(error)
            }
          }
        },
      }
    }
    // Open drawer
    return {
      to: {
        ...location,
        search: setSearchParams<GlobalDrawerParams<"contentUsage">>(location.search, {
          u: Relay.fromGlobalId(contentUsage.id).id,
        }),
      },
    }
  }
}

export default ContentUsageListItem

interface StyleProps {
  isLocked: boolean
}

const useStyles = makeUseStyles((theme) => ({
  itemContainer: ({ isLocked }: StyleProps) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: theme.spacing(1.5, 0),
    height: "104px",
    background: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.big,
    boxShadow: theme.palette.groovyDepths.insideCard,
    border: theme.palette.constants.borderSmall,
    ...styleIf(isLocked, {
      opacity: "0.75",
    }),

    "&:hover": {
      transition: theme.transitions.create(["boxShadow", "cursor"], {
        duration: theme.transitions.duration.standard,
        easing: theme.transitions.easing.easeInOut,
      }),
      cursor: "pointer",
      boxShadow: theme.palette.groovyDepths.boxShadow,
      background: theme.palette.groovy.neutral[100],
    },
  }),
  itemHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 0),
    width: "100%",
    gap: theme.spacing(1.5),

    [theme.breakpoints.down("xs")]: {
      paddingBottom: theme.spacing(1),
    },
  },
  lhsContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    cursor: "pointer",
    position: "relative",
    marginLeft: theme.spacing(-1.5),

    [theme.breakpoints.down("xs")]: {
      paddingBottom: theme.spacing(1),
    },
  },
  completionContainer: {
    display: "flex",
    alignItems: "center",
    width: "24px",
  },
  rhsContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    justifyContent: "flex-end",
    gap: theme.spacing(1),

    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-end",
    },
  },
  dragButtonContainer: {
    alignItems: "center",
    marginLeft: theme.spacing(-0.5),
    marginRight: theme.spacing(0.5),
  },
  lockIcon: {
    display: "flex",
    alignItems: "center",
    alignSelf: "center",
  },
  buttonsContainer: {
    width: "32px",
  },
  circle: {
    width: "4px",
    height: "4px",
    borderRadius: "50%",
    backgroundColor: theme.palette.groovy.grey[500],
  },
  progressIcon: {
    margin: 0,
    flexShrink: 0,
    width: "24px",
    height: "24px",
  },
}))

export function ContentUsageListItemSkeleton() {
  const classes = useStyles({ isLocked: false })
  return (
    <DiscoSection className={classes.itemContainer}>
      <div className={classes.itemHeader}>
        <div className={classes.lhsContainer}>
          <ContentThumbnailWithDetailsSkeleton
            thumbnailWidth={"120px"}
            thumbnailHeight={"60px"}
          />
        </div>
        <div className={classes.rhsContainer}>
          <DiscoChipSkeleton color={"grey"} width={"90px"} />
        </div>
      </div>
    </DiscoSection>
  )
}
